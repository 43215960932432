import {Component, OnInit} from '@angular/core';
import {HeroFeatureMenuItemComponent} from "./hero-feature-menu-item/hero-feature-menu-item.component";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    standalone: true,
    imports: [IonicModule, HeroFeatureMenuItemComponent, CommonModule],
})
export class HomeComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
