import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';

import {Storage} from '@ionic/storage-angular';
import {AuthService} from '@auth0/auth0-angular';
import {IonicModule, ModalController, NavController} from '@ionic/angular';
import {lastValueFrom, Subscription} from 'rxjs';
import {App} from '@capacitor/app';
import {AlertService} from './services/alert.service';
import {Capacitor, PluginListenerHandle} from '@capacitor/core';
import {environment} from 'src/environments/environment';
import {Browser} from "@capacitor/browser";
import {LoginService} from "./services/login.service";

import {RouterLink, RouterOutlet} from "@angular/router";
import {CommonModule} from "@angular/common";
import {NgcCookieConsentService} from "ngx-cookieconsent";
import {AppUpdateNotifierComponent} from "./components/app-update-notifier/app-update-notifier.component";
import {ValuesService} from "./services/values.service";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    standalone: true,
    imports: [IonicModule, CommonModule, RouterLink],
})
export class AppComponent implements OnInit, OnDestroy {
    isAdmin = false;
    private _userSub: Subscription;
    private _adminRoles = ['headoffice', 'superuser'];
    private _listener: PluginListenerHandle;

    constructor(
        public auth: AuthService,
        public navCtrl: NavController,
        public loginService: LoginService,
        private _storage: Storage,
        private _ngZone: NgZone,
        private _alert: AlertService,
        private _consentService: NgcCookieConsentService,
        private _modal: ModalController,
        private _valuesService: ValuesService,
    ) {
    }

    async ngOnInit() {
        await this._storage.create();
        this._userSub = this.auth.user$.subscribe(user => {
            if (environment.production) {
                this.isAdmin = user && (user['lido-delivery-api/roles'] as string[])?.some(r => this._adminRoles.includes(r));
            } else {
                this.isAdmin = user && (user['lido-delivery-api/roles'] as string[])?.some(r => this._adminRoles.includes(r));
            }
        });

        if (Capacitor.isNativePlatform()) {
            await this._listenForOpenUrl();
            await this._checkNeedsUpdate();
        }

        if (!environment.production) {
            console.log('not showing consent because env.production is ' + environment.production);
            this._consentService.destroy();
        }
    }

    ngOnDestroy() {
        this._userSub.unsubscribe();
        this._listener?.remove();
    }

    private async _listenForOpenUrl() {
        this._listener = await App.addListener('appUrlOpen', ({url}) => {
            this._ngZone.run(async () => {
                if (!url?.startsWith(environment.auth0CallbackUrl)) {
                    await this.navCtrl.navigateForward(url.replace(new URL(url).origin, ''));
                    return;
                }
                if (!url.includes('state=') || !url.includes('code=')) {
                    await this.navCtrl.navigateRoot('/');
                    return;
                }
                await lastValueFrom(this.auth.handleRedirectCallback(url));
                await Browser.close();
                await this._alert.success('Login successful');
            });
        });
    }

    private async _checkNeedsUpdate() {
        try {
            const appInfo = await App.getInfo();
            const apiVersion = await this._valuesService.getApiVersionAsync();
            const parts = apiVersion.version.split('.');
            const major = parseInt(parts[0], 10);
            const minor = parseInt(parts[1], 10);
            const patch = parseInt(parts[2], 10);
            const currentParts = appInfo.version.split('.');
            const currentMajor = parseInt(currentParts[0], 10);
            const currentMinor = parseInt(currentParts[1], 10);
            const currentPatch = parseInt(currentParts[2], 10);
            if (major > currentMajor || minor > currentMinor || patch > currentPatch) {
                const modal = await this._modal.create({
                    component: AppUpdateNotifierComponent,
                    backdropDismiss: false,
                    canDismiss: false,
                });

                await modal.present();
            }
        } catch (e) {
            console.error(e);
        }
    }
}
