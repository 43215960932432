import {computed, Injectable, signal} from '@angular/core';
import {MenuItemForListDto} from "../_dtos/menu-items/menu-items-for-list-dto";
import {Storage} from "@ionic/storage-angular";
import {OutOfStockDto} from "../_dtos/status-dtos/out-of-stock-dto";
import {CartItem} from "../_models/cart-items";
import {InventoryService} from "./inventory.service";
import {LoggerService} from "./logger.service";
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop";
import {ErrorHandlerService} from "./error-handler.service";
import {PixelService} from "ngx-pixel-eventid";

@Injectable({
    providedIn: 'root'
})
export class CartService {
    private _storageKey = 'cart';

    $cartItems = signal<CartItem[] | undefined>(undefined);
    $cartTotal = signal(0);
    $hasOutOfStockItems = signal(false);

    constructor(
        private _storage: Storage,
        private _inventoryService: InventoryService,
        private _logger: LoggerService,
        private _eh: ErrorHandlerService,
        private _pixel: PixelService
    ) {
        toObservable(this._inventoryService.$outOfStockItems)
            .pipe(takeUntilDestroyed())
            .subscribe(async outOfStockItems => {
                this._logger.debug('CartService -- got out of stock items', outOfStockItems);
                const items = this.$cartItems();
                if (!items) return;

                for (const i of items) {
                    i.isOutOfStock = outOfStockItems.some(x => x.menuItemId === i.menuItem.id);
                }

                await this._setCartItems(items);
            });
    }

    async loadCartItemsAsync() {
        try {
            this._logger.debug('CartService -- loading cart items');
            const cartItems = await this._storage.get(this._storageKey);
            await this._setCartItems(cartItems ?? []);
        } catch (e) {
            await this._eh.handle(e);
        }
    }

    async updateCartQuantity(menuItem: MenuItemForListDto, quantity: number) {
        if (quantity <= 0) {
            await this._removeFromCart(menuItem.id);
        } else {
            await this._setCartItemQuantity(menuItem, quantity);
        }
    }

    async clearCart() {
        if (!this.$cartItems().length) return;
        await this._setCartItems([]);
    }

    getQuantity(menuItemId: number) {
        if (!this.$cartItems()?.length) return 0;
        return this.$cartItems().find(x => x.menuItem.id === menuItemId)?.quantity ?? 0;
    }

    private async _removeFromCart(menuItemId: number) {
        const items = this.$cartItems().slice();

        const index = items.findIndex(x => x.menuItem.id === menuItemId);
        if (index < 0) return;

        items.splice(index, 1);
        await this._setCartItems(items);
    }

    private async _setCartItemQuantity(menuItem: MenuItemForListDto, quantity: number) {
        if (this.$cartItems() == null) return;

        const currentItems = this.$cartItems().slice();
        this._logger.debug('CartService -- setting cart item quantity', menuItem, quantity);

        const existingCartItem = currentItems.find(x => x.menuItem.id === menuItem.id);

        if (existingCartItem) {
            const oldQuantity = existingCartItem.quantity;
            existingCartItem.quantity = quantity;

            this._pixel.track('AddToCart', {
                content_ids: [menuItem.id],
                content_name: menuItem.name,
                content_type: 'product',
                value: quantity * menuItem.price,
                currency: 'PHP',
                num_items: quantity - oldQuantity
            });
        } else {
            currentItems.push({
                quantity,
                isOutOfStock: this._inventoryService.$outOfStockItems().some(x => x.menuItemId === menuItem.id),
                menuItem
            });

            this._pixel.track('AddToCart', {
                content_ids: [menuItem.id],
                content_name: menuItem.name,
                content_type: 'product',
                value: quantity * menuItem.price,
                currency: 'PHP',
                num_items: quantity
            });
        }

        await this._setCartItems(currentItems);
    }

    private _computeCartTotal() {
        this._logger.debug('CartService -- computing cart total...');
        const cartItems = this.$cartItems();
        if (!cartItems?.length) return 0;
        return cartItems
            .filter(x => !x.isOutOfStock)
            .reduce((acc, cartItem) => acc + cartItem.menuItem.price * cartItem.quantity, 0);
    }

    private async _setCartItems(cartItems: CartItem[]) {
        this._logger.debug('CartService -- setting cart items', cartItems);
        await this._storage.set(this._storageKey, cartItems);
        this.$cartItems.set(cartItems);
        this.$cartTotal.set(this._computeCartTotal());
        this.$hasOutOfStockItems.set(this.$cartItems().some(x => x.isOutOfStock));
    }
}
